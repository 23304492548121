import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import UiKit from './UiKit';
import { AllBwics } from './allBWICS/AllBwics';
import { Portfolio } from './portfolio/Portfolio';
import { EditPortfolio } from './portfolio/EditPortfolio';
import { PortfolioDocuments } from './portfolio/PortfolioDocuments';
import { Dealers } from './dealers/Dealers';
import { Wizard as NewBWIC } from './newBWIC';
import { ManageCompanies, EditCompany } from './management/companies';
import { ManageBWICs } from './management/parsedBWICs';
import { Login, Logout, ResetPassword, SetUpPassword, Signup, SignupConfirm } from './account';
import { Shield, NotFound, Internal, Forbidden } from './errors';
import { Layout, AnonymousLayout, SignPagesLayout } from './layouts';
import { CustomRoute } from './routing';
import { routes, roles } from '../constants';
import { PrivacyPolicy, ATSRulebook, TermsAndConditions, Disclaimers } from './agreements';
import { Profile } from './profile/Profile';
import { EditBWIC } from './management/editParsedBWIC';
import { ContactsSave } from './contactsSave';
import { BiddingView } from './bidding/BiddingView';
import { user } from '../user';
import { ConnectionStatusToast, Notifications } from './notifications';
import { BlotterView } from './blotter/BlotterView';
import { accountActions, apiOperationActions, salesRepresentativeActions } from "../actions";
import { ShowFor } from './access';
import { TradesView } from './trades';
import { ImportSecurities } from "./management/importSecurities";
import { Dashboard } from './dashboard/Dashboard';
import { OnBoardingTooltipSupportPopup } from './onBoarding/SupportPopup';
import { IdentityCodeRequest } from './account/verify-identity/IdentityCodeRequest';
import { IdentityCodeSubmit } from './account/verify-identity/IdentityCodeSubmit';
import '../styles/main.scss';
import { SellerBuyside } from './seller-buyside/SellerBuyside';
import { IssuanceMonitor } from './amrPipeline/aggregated/IssuanceMonitor';
import { PipelineDetailed } from './amrPipeline/detailed/PipelineDetailed';
import { PipelineHistory } from './amrPipeline/history/PipelineHistory';
import { UserProfilePhotoUploaderPopup } from './user-profile-photo/UserProfilePhotoUploaderPopup';
import { BuildVersion } from './management/buildVersion/BuildVersion';
import { InventoryView } from './inventory/InventoryView';
import { BdInventoryEdit } from './inventory/bd/BdInventoryEdit';
import { AdminInventoryEdit } from './inventory/AdminInventoryEdit';
import { InventoryDocuments } from './inventory/bd/InventoryDocuments';
import { AllBwicsTab } from './allBWICS/AllBwicsTab';
import { ClientsView } from './clients/companies/ClientsView';
import { AdminEditUser } from './management/companies/AdminEditUser';
import { ManageCleanRedisCache } from './management/storage/ManageCleanRedisCache';
import { awaitingTradesActions } from '../actions/entities/awaitingTrades.actions';
import { DownloadDocument } from './download-document/DownloadDocument';
import { ManageEmails } from './management/manage-emails/ManageEmails';
import { ManageTokens } from './management/manageTokens/ManageTokens';
import { EditNews } from './management/news/EditNews'
import { RecourcesView } from './help-resources/HelpRecourcesView';
import { CloManagers } from './clo-managers/CloManagers';
import { ArrangerClientSignup } from './account/arrangersClient/ArrangerClientSignup';
import { ArrangerClientSignupConfirm } from './account/arrangersClient/ArrangerClientSignupConfirm';
import { IssuanceMonitorNoData } from './amrPipeline/IssuanceMonitorNoData';
import { appConfig } from '../app-config';
import { News } from './news/News';
import { WithAmrRegistration } from './routing/WithAmrRegistration';
import { BwicSubmitted } from './submit/BwicSubmitted';
//import { Eval } from './eval/Eval';
import { Banks } from './banks/Banks';
import { Deals } from './deals/Deals';
import { PipelineDetailedLimitedAccess } from './amrPipeline/detailed/PipelineDetailedLimitedAccess';

const App = () => {
    const dispatch = useDispatch();
    const authenticated = useSelector(state => state.authentication.authenticated);
    const isUploadUserProfilePhotoPopupVisible = useSelector(state => state.profile.isUploadUserProfilePhotoPopupVisible);

    useEffect(() => {
        const currentUser = user.current();
        if (currentUser) {
            dispatch(accountActions.refreshAuthentication());
            dispatch(salesRepresentativeActions.loadUserSalesRepresentatives());
        }
    }, [dispatch]);

    useEffect(() => {
        if(!authenticated) return;

        dispatch(apiOperationActions.trackStuckOperations());

        if (user.hasRoles(roles.SellerTrader, roles.SellerViewer, roles.BrokerDealerTrader)) {
            dispatch(awaitingTradesActions.requestTrades());
        }
    }, [authenticated, dispatch])

    const defaultRedirect = () => {
        if (!user.isAuthenticated()) {
            return <Redirect to={routes.login} />
        } else if (user.hasSingleRole(roles.SellerAdministrator)) {
            return <Redirect to={routes.sellerSell} />
        } else if (user.hasSingleRole(roles.ArrangersClient)) {
            return <Redirect to={routes.AMRPipeline} />;
        }

        const homeRoute = user.hasRoles(roles.Viewer)
            ? routes.dashboard
            : routes.newsUrl();

        return <Redirect to={process.env.REACT_APP_DEV_LOCAL_DEFAULT ?? homeRoute} />
    };

    return (
        <Shield>
            <Switch>
                <Route exact path={routes.root} render={defaultRedirect} />
                <Route exact path={routes.logout} component={Logout} />
                <CustomRoute exact path={routes.arrangersClientSignUp} layout={SignPagesLayout} component={ArrangerClientSignup} title="Sign up - KTX ATS Platform" />
                <CustomRoute exact path={routes.arrangersClientSignUpConfirm} layout={AnonymousLayout} component={ArrangerClientSignupConfirm} title="Sign up - KTX ATS Platform" />
                <CustomRoute exact path={routes.allBWICs} allowAnonymous={false} layout={Layout} allowRoles={[...roles.seller(), ...roles.bd(), roles.Administrator, roles.Viewer, roles.SellerAdministrator, roles.DataEntry]} title="BWIC Monitor - KTX ATS Platform">
                    {
                        user.hasSingleRole(roles.SellerAdministrator)
                            ? <Redirect to={routes.sellerSell} />
                            : <WithAmrRegistration component={AllBwics} myBwicsOnly={false} />
                    }
                </CustomRoute>
                <CustomRoute exact path={routes.myBWICs} allowAnonymous={false} allowRoles={[...roles.seller(), ...roles.bd(), roles.SellerAdministrator]} layout={Layout} title="BWIC Monitor - KTX ATS Platform">
                    {
                        user.hasRoles(...roles.seller(), roles.SellerAdministrator)
                            ? <Redirect to={routes.sellerSell} />
                            : <WithAmrRegistration component={AllBwics} myBwicsOnly={true} activeTab={AllBwicsTab.Buy} />
                    }
                </CustomRoute>
                <Route exact path={routes.allBWICsOld} render={props => <Redirect to={{ pathname: routes.allBWICs, search: props.location.search }} />} />
                <CustomRoute exact path={routes.sellerBuy} allowAnonymous={false} allowRoles={roles.seller()} blockFeatureRedirectPath={routes.allBWICs} component={SellerBuyside} layout={Layout} title="BWIC Monitor - KTX ATS Platform" />
                <CustomRoute exact path={routes.sellerSell} allowAnonymous={false} allowRoles={[...roles.seller(), roles.SellerAdministrator]} layout={Layout} title="BWIC Monitor - KTX ATS Platform" >
                    <WithAmrRegistration component={AllBwics} myBwicsOnly={true} activeTab={AllBwicsTab.Sell} />
                </CustomRoute>
                <CustomRoute exact path={routes.allToAll} allowAnonymous={false} allowRoles={[...roles.bd()]} layout={Layout} title="BWIC Monitor - KTX ATS Platform" >
                    <WithAmrRegistration component={AllBwics} myBwicsOnly={true} isAllToAll={true} activeTab={AllBwicsTab.Sell} />
                </CustomRoute>
                <CustomRoute exact={true} path={routes.newBWICCreated} allowAnonymous={false} allowRoles={[roles.SellerTrader, roles.BrokerDealerTrader]} blockFeatureRedirectPath={routes.allBWICs} layout={Layout} component={BwicSubmitted} title="New BWIC - KTX ATS Platform" />
                <CustomRoute exact={false} path={routes.newBWIC} allowAnonymous={false} allowRoles={[roles.SellerTrader, roles.BrokerDealerTrader]} blockFeatureRedirectPath={routes.allBWICs} layout={Layout} component={NewBWIC} title="New BWIC - KTX ATS Platform" />

                <CustomRoute exact path={routes.portfolio} allowAnonymous={false} allowRoles={[...roles.seller(), ...roles.bd(), roles.Viewer, roles.Administrator, roles.DataEntry]} layout={Layout} component={Portfolio} title="Portfolio - KTX ATS Platform" />
                <CustomRoute exact path={routes.newPortfolio} allowAnonymous={false} allowRoles={[...roles.seller(), ...roles.bd(), roles.Viewer, roles.Administrator, roles.DataEntry]} layout={Layout} component={EditPortfolio} title="Edit Portfolio - KTX ATS Platform" />
                <CustomRoute exact path={routes.editPortfolio} allowAnonymous={false} allowRoles={[...roles.seller(), ...roles.bd(), roles.Viewer, roles.Administrator, roles.DataEntry]} layout={Layout} component={EditPortfolio} title="Edit Portfolio - KTX ATS Platform" />
                <CustomRoute exact path={routes.portfolioDocuments} allowAnonymous={false} allowRoles={[...roles.seller(), ...roles.bd(), roles.Viewer, roles.Administrator, roles.DataEntry]} layout={Layout} component={PortfolioDocuments} title="Upload Documents - KTX ATS Platform" />

                <CustomRoute exact path={routes.dealers} allowAnonymous={false} allowRoles={roles.seller()} layout={Layout} component={Dealers} title="Dealers - KTX ATS Platform" />
                <CustomRoute exact path={routes.importContacts} allowAnonymous={false} allowRoles={roles.seller()} layout={Layout} component={ContactsSave} title="Import Contacts - KTX ATS Platform" />
                <CustomRoute exact path={routes.editContacts} allowAnonymous={false} allowRoles={roles.seller()} layout={Layout} component={ContactsSave} title="Edit All - KTX ATS Platform" />

                <CustomRoute exact path={routes.manageCompanies} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={ManageCompanies} title="Manage Companies - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageCompanyMembers} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={ManageCompanies} title="Manage Companies - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageCompaniesCreateUser} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={AdminEditUser} title="Manage Create User - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageCompaniesEditUser} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={AdminEditUser} title="Manage Edit User - KTX ATS Platform" />
                <CustomRoute exact path={routes.addCompany} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={EditCompany} title="Create Company - KTX ATS Platform" />
                <CustomRoute exact path={routes.editCompany} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={EditCompany} title="Edit Company - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageClearRedisState} allowAnonymous={false} allowRoles={[roles.Administrator, roles.DataEntry]} layout={Layout} component={ManageCleanRedisCache} title="Storage - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageParsedBwics} allowAnonymous={false} allowRoles={[roles.Administrator, roles.DataEntry]} layout={Layout} component={ManageBWICs} title="Manage BWICs - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageImportSecurities} allowAnonymous={false} allowRoles={[roles.Administrator, roles.DataEntry]} layout={Layout} component={ImportSecurities} title="Securities Import - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageEmails} allowAnonymous={false} allowRoles={[roles.Administrator, roles.DataEntry]} layout={Layout} component={ManageEmails} title="Emails - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageTokens} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={ManageTokens} title="Manage API Access Token - KTX ATS Platform" />
                <CustomRoute exact path={routes.manageCloManager} allowAnonymous={false} allowRoles={roles.cloManagersAccess()} layout={Layout} title="CLO Managers">
                    <WithAmrRegistration component={CloManagers} />
                </CustomRoute>
                <CustomRoute exact path={routes.manageBank} allowAnonymous={false} allowRoles={roles.banksAccess()} layout={Layout} title="Banks">
                    <WithAmrRegistration component={Banks} />
                </CustomRoute>
                <CustomRoute exact path={[routes.manageCreateNews, routes.manageNews]} allowAnonymous={false} allowRoles={[roles.Administrator, roles.DataEntry]} layout={Layout} component={EditNews} title="KopenTech News" />

                <CustomRoute exact path={routes.login} layout={SignPagesLayout} component={Login} title="KTX ATS Platform" />
                <CustomRoute exact path={routes.forgotPassword} layout={SignPagesLayout} component={ResetPassword} title="Forgot your password? - KTX ATS Platform" />
                <CustomRoute exact path={routes.forgotPasswordFinished} layout={AnonymousLayout} component={ResetPassword} title="Forgot your password? - KTX ATS Platform" />
                <CustomRoute exact path={routes.setPassword} layout={AnonymousLayout} component={SetUpPassword} />
                <CustomRoute exact path={routes.signUp} layout={SignPagesLayout} component={Signup} title="Sign up - KTX ATS Platform" />
                <CustomRoute exact path={routes.signUpConfirm} layout={AnonymousLayout} component={SignupConfirm} title="Sign up - KTX ATS Platform" />

                <CustomRoute exact path={routes.internal} isError={true} allowAnonymous={true} layout={authenticated ? Layout : AnonymousLayout} component={Internal} />
                <CustomRoute exact path={routes.notFound} isError={true} allowAnonymous={true} layout={authenticated ? Layout : AnonymousLayout} component={NotFound} />
                <CustomRoute exact path={routes.forbidden} isError={true} allowAnonymous={true} layout={authenticated ? Layout : AnonymousLayout} component={Forbidden} />

                <CustomRoute exact path={routes.privacyPolicy} allowAnonymous={true} layout={authenticated ? Layout : AnonymousLayout} component={PrivacyPolicy} title="Privacy Policy - KTX ATS Platform" />
                <CustomRoute exact path={routes.ATSRulebook} allowAnonymous={true} layout={authenticated ? Layout : AnonymousLayout} component={ATSRulebook} title="ATS Rulebook - KTX ATS Platform" />
                <CustomRoute exact path={routes.termsAndConditions} allowAnonymous={true} layout={authenticated ? Layout : AnonymousLayout} component={TermsAndConditions} title="Terms and Conditions - KTX ATS Platform" />
                <CustomRoute exact={false} path={[routes.disclaimers, routes.disclosure]} allowAnonymous={true} layout={authenticated ? Layout : AnonymousLayout} component={Disclaimers} title="Disclaimers - KTX ATS Platform" />

                <CustomRoute exact path={routes.newParsedBWIC} allowAnonymous={false} layout={Layout} allowRoles={[roles.Administrator, roles.DataEntry]} component={EditBWIC} title="New Parsed BWIC - KTX ATS Platform" />
                <CustomRoute exact path={routes.editParsedBWIC} allowAnonymous={false} layout={Layout} allowRoles={[roles.Administrator, roles.DataEntry]} component={EditBWIC} title="Edit Parsed BWIC - KTX ATS Platform" />

                <CustomRoute exact={false} path={routes.profile} allowAnonymous={false} layout={Layout} component={Profile} title="Profile - KTX ATS Platform" />

                <CustomRoute exact path={routes.bidding} allowAnonymous={false} layout={Layout} allowRoles={[...roles.seller(), ...roles.bd(), ...roles.admin()]} blockFeatureRedirectPath={routes.allBWICs} component={BiddingView} />

                <CustomRoute path={routes.blotter} allowAnonymous={false} layout={Layout} allowRoles={[...roles.seller(), ...roles.bd()]} blockFeatureRedirectPath={routes.allBWICs} component={BlotterView} title="Blotter - KTX ATS Platform" />

                <CustomRoute exact path={routes.dashboard} allowAnonymous={false} layout={Layout} allowRoles={[...roles.bd(), ...roles.seller(), roles.DataEntry, roles.Administrator, roles.Viewer, roles.Media]} title="Dashboard - KTX ATS Platform">
                    <WithAmrRegistration component={Dashboard} />
                </CustomRoute>

                <CustomRoute exact path={routes.news} allowAnonymous={false} layout={Layout} allowRoles={[...roles.bd(), ...roles.seller(), roles.DataEntry, roles.Administrator]} title="K-Watch News">
                    <WithAmrRegistration component={News} />
                </CustomRoute>

                <CustomRoute exact path={routes.trades} allowAnonymous={false} layout={Layout} allowRoles={[roles.Administrator, roles.DataEntry]} component={TradesView} title="Trades - KTX ATS Platform" />

                <CustomRoute path={routes.clients} allowAnonymous={false} allowRoles={roles.bd()} layout={Layout} title="Clients - KTX ATS Platform">
                    {
                        user.current()?.isClearingBank
                            ? <ClientsView />
                            : <Redirect to={routes.allBWICs} />
                    }
                </CustomRoute>

                <CustomRoute exact path={routes.AMRPipeline} allowAnonymous={false} allowRoles={roles.issuanceMonitorAccess()} layout={Layout} title="Issuance Monitor - KTX ATS Platform">
                    <WithAmrRegistration component={IssuanceMonitor} />
                </CustomRoute>
                <CustomRoute exact path={routes.issuanceMonitorWithNoData} allowAnonymous={false} allowRoles={roles.issuanceMonitorAccess()} layout={Layout} component={IssuanceMonitorNoData} title="Issuance Monitor - KTX ATS Platform" />
                <CustomRoute exact path={routes.transactionHistory} allowAnonymous={false} allowRoles={roles.issuanceMonitorAccess()} layout={Layout} component={PipelineHistory} title="Issuance Monitor - KopenTech" />
                <CustomRoute exact path={[routes.transactionDetail, routes.transactionClass]} allowAnonymous={false} allowRoles={roles.issuanceMonitorAccess()} layout={Layout} title="Issuance Monitor - KTX ATS Platform">
                    <WithAmrRegistration component={PipelineDetailed} />
                </CustomRoute>

                <CustomRoute exact path={[routes.transactionLimitedAccess, routes.transactionClass]} allowAnonymous={false} allowRoles={roles.issuanceMonitorAccess()} layout={Layout} title="Issuance Monitor - KTX ATS Platform">
                    <WithAmrRegistration component={PipelineDetailedLimitedAccess} />
                </CustomRoute>

                <CustomRoute exact path={routes.verifyIdentitySubmit} allowAnonymous={true} layout={AnonymousLayout} component={IdentityCodeSubmit} title="Verify your identity - KTX ATS Platform" />
                <CustomRoute exact path={routes.verifyIdentityRequest} allowAnonymous={true} layout={AnonymousLayout} component={IdentityCodeRequest} title="Verify your identity - KTX ATS Platform" />

                <CustomRoute exact path={routes.inventory} allowAnonymous={false} layout={Layout} allowRoles={[...roles.bd(), ...roles.seller(), roles.Administrator, roles.DataEntry, roles.Viewer]} component={InventoryView} title={user.hasRoles(...roles.bd()) ? 'Inventory - KTX ATS Platform' : 'Dealer Inventory - KTX ATS Platform'} />
                <CustomRoute exact path={routes.inventoryEdit} allowAnonymous={false} layout={Layout} allowRoles={[roles.BrokerDealerTrader]} component={BdInventoryEdit} title="Edit Inventory - KTX ATS Platform" />
                <CustomRoute exact path={routes.inventoryDocuments} allowAnonymous={false} layout={Layout} allowRoles={roles.bd()} component={InventoryDocuments} title="Inventory - KTX ATS Platform" />
                <CustomRoute exact path={routes.adminInventoryEdit} allowAnonymous={false} layout={Layout} allowRoles={[roles.Administrator, roles.DataEntry]} component={AdminInventoryEdit} title="Edit Inventory - KTX ATS Platform" />

                <CustomRoute exact={false} path={routes.resources} allowAnonymous={false} layout={Layout} component={RecourcesView} title="Resources - KopenTech" />

                <CustomRoute exact path={routes.downloadStatic} allowAnonymous={false} component={DownloadDocument} title="KTX ATS Platform" />

                <CustomRoute path={routes.deals} allowAnonymous={false} allowRoles={roles.dealsAccess()} layout={Layout} title="Deals">
                    <WithAmrRegistration component={Deals} />
                </CustomRoute>

                {/* AMR-11246 [staging] BWIC:: Hide the Eval landing page in the Main menu for 6.4 BWIC release
                <CustomRoute exact path={routes.eval} allowAnonymous={false} layout={Layout} allowRoles={[roles.Administrator, roles.DataEntry, roles.Viewer, ...roles.seller(), ...roles.bd()]} component={Eval} title="Eval" /> */}

                {(process.env.NODE_ENV === 'development' || !!process.env.REACT_APP_UI_KIT_VISIBLE) && <CustomRoute exact path={routes.uiKit} allowAnonymous={true} layout={Layout} component={UiKit} />}

                {appConfig.uiKitVisible && <CustomRoute exact path={routes.uiKit} allowAnonymous={true} layout={Layout} component={UiKit} />}

                {process.env.REACT_APP_BUILD_VERSION_VISIBLE && <CustomRoute exact path={routes.manageBuildVersion} allowAnonymous={false} allowRoles={[roles.Administrator]} layout={Layout} component={BuildVersion} title="Build version" />}

                <CustomRoute allowAnonymous={true} isError={true} layout={user.isAuthenticated() ? Layout : AnonymousLayout} component={NotFound} />
            </Switch>
            {
                authenticated &&
                <>
                    <ShowFor roles={[roles.Administrator, ...roles.bd(), ...roles.seller(), roles.Viewer]}>
                        <ConnectionStatusToast disabled />
                    </ShowFor>
                    <OnBoardingTooltipSupportPopup />
                </>
            }
            <Notifications />
            {isUploadUserProfilePhotoPopupVisible && <UserProfilePhotoUploaderPopup />}
        </Shield>
    );
};

export default App;

